<template>
  <section>
    <div class="content-header">
      <h2>Constructing belonging in a diverse campus community </h2>
    </div>
    <div class="content-wrapper">
      <p>A diverse student body holds the promise of enriching the learning experience of all students in a given school, but will this diversity inhibit students’ sense of belonging at that institution?</p>
      <p><a href="https://www.tandfonline.com/doi/abs/10.2202/1940-1639.1085" target="_blank">Constructing belonging in a diverse campus community</a> by Robin Cooper discusses how a sense of belonging relates to student success. It also explores how higher education professionals can support students through the purposeful cultivation of collective identity among a diverse student body, as well as through the use of cultural tools that promote a sense of belonging. In addition, this article describes a model of student engagement recently developed at one large, private university in the Southeast with a highly diverse student body.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
